import axios from 'axios';
import { serverMode } from '../../config/config';

const baseURL =
  serverMode === 'development'
    ? 'https://localhost:5001/api/'
    : serverMode === 'testServer'
    ? 'https://testapi.vita.app.br/api'
    : 'https://api.vita.app.br/api';

const apiClient = axios.create({
  baseURL: baseURL,

  withCredentials: false, // default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(function (config) {
  // Do something before request is sent
  let authKey = '';
  config.headers['Authorization'] = 'Basic ' + authKey;
  return config;
});

export default apiClient;
