import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import { toastNotification } from '../shared/toastNotification';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { backendUrl } from '../config/config';
import { EyeOffIcon, EyeOpenIcon, Position, Text } from 'evergreen-ui';
import { checkString } from '../helpers/signInUpValidation';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const NewCustomerForm = () => {
  const [open, setOpen] = useState(false);
  const { selectedPlan, selectedPeriodicity, selectedPrice } = useSelector(
    (state) => state.newCustomer
  );
  const navigate = useNavigate();

  const [inputType, setInputType] = useState(true);
  const [iconState, setIconState] = useState(true);

  const togglePassword = () => {
    setInputType((old) => !old);
    setIconState((old) => !old);
  };

  const handleRegister = async (data) => {
    data.plan = selectedPlan;
    data.cycle = selectedPeriodicity;
    console.log(data);
    setOpen(true);
    fetch(`${backendUrl}/customers/new`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.dbUser) {
          setOpen(false);
          toastNotification('success', 'Cadastro realizado com sucesso!');
          navigate('/cadastro-finalizado');
        } else {
          if (res.message.includes('billingType')) {
            toastNotification('error', 'Método de pagamento inválido');
          }
          if (res.message.includes('O CPF/CNPJ informado é inválido')) {
            toastNotification('error', 'O CPF/CNPJ informado é inválido');
          }
          if (res.message.includes('The email address is already in use')) {
            toastNotification('error', 'O e-mail informado já está em uso');
          }
          if (
            res.message.includes('The email address is improperly formatted')
          ) {
            toastNotification(
              'error',
              'O e-mail informado está com a formatação incorreta'
            );
          }
          setOpen(false);
        }
        console.log('Resposta do server: ', res);

        return res;
      })
      .catch((err) => {
        console.log(err);
        setOpen(false);
        toastNotification('error', 'Erro desconhecido');
      });
  };

  const {
    formState,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const {
    errors,
    dirtyFields,
    isDirty,
    isSubmitting,
    touchedFields,
    submitCount,
  } = formState;

  const formatPlanText = () => {
    if (selectedPlan === 'LIBERAL') {
      return 'Liberal';
    }
    if (selectedPlan === 'AVANÇADO') {
      return 'Avançado';
    }
    if (selectedPlan === 'EMPRESARIAL') {
      return 'Empresarial';
    }
    return 'Nenhum plano selecionado';
  };

  const formatPeriodicityText = () => {
    if (selectedPeriodicity === 'MONTHLY') {
      return 'Mensal';
    }
    if (selectedPeriodicity === 'SEMIANNUALLY') {
      return 'SEMIANNUALLY';
    }
    if (selectedPeriodicity === 'YEARLY') {
      return 'Anual';
    }
    return 'Nenhum ciclo de pagamento selecionado';
  };

  // useEffect(() => {
  //   (!selectedPlan || !selectedPeriodicity) && navigate('/planos');
  // }, [selectedPlan, selectedPeriodicity]);

  if (!selectedPlan || !selectedPeriodicity) {
    return (
      <div className='flex flex-col justify-center items-center w-full gap-6'>
        <h1 className='text-5xl'>Ops!</h1>
        <h1 className='text-2xl'>
          Parece que você não selecionou um plano ou um ciclo de pagamento
        </h1>
        <Button
          className='mt-4'
          variant='contained'
          color='primary'
          onClick={() => navigate('/planos')}
        >
          Voltar para a seleção de planos
        </Button>
      </div>
    );
  }

  return (
    <div className='flex flex-col justify-center items-center te w-full'>
      <div className='flex flex-col justify-center items-center gap-8 w-2/3'>
        <h1 className='text-5xl'>Falta pouco!</h1>
        <h1 className='text-2xl'>
          Finalize seu cadastro para para concluir sua assinatura e acessar o
          Vita
        </h1>
        <h1 className='text-2xl font-semibold'>
          {`Plano escolhido: `}
          <strong className='text-green-800'>{formatPlanText()}</strong>
        </h1>
        <h1 className='text-2xl font-semibold'>
          {`Ciclo de pagamento: `}
          <strong className='text-green-800'>{formatPeriodicityText()}</strong>
        </h1>
      </div>
      <form onSubmit={handleSubmit(handleRegister)}>
        <div className='flex flex-col p-4 gap-8 mt-6 '>
          <div className='flex flex-col gap-4 flex-wrap '>
            <TextField
              {...register('name', {
                required: true,
              })}
              id='name'
              label='Nome Completo'
              placeholder='Nome Completo'
              type='text'
            />
            <TextField
              {...register(
                'email',

                {
                  pattern: {
                    value:
                      /^[a-zA-Z0-9.]+@[a-zA-Z0-9]{2,}.[a-zA-Z]{2,}(.?[a-z]{2,3})?$/gm,
                    message: 'Digite um e-mail válido.',
                  },
                  required: 'Obrigatório.',
                }
              )}
              minWidth={250}
              label='E-mail'
              type='email'
              validationMessage={errors?.email?.message}
            />
            <FormControl fullWidth>
              <InputLabel htmlFor='password'>Senha</InputLabel>
              <OutlinedInput
                {...register(
                  'password',

                  {
                    pattern: {
                      value:
                        /^(?=.*\d)(?=.*[!@#$%&*])(?=.*[a-z])(?=.*[A-Z]).{6,20}$/gm,
                      // message: 'Padrão incorreto.',
                    },
                    minLength: {
                      value: 6,
                      // message: 'A senha deve conter pelo menos 6 caracteres',
                    },
                  }
                )}
                type={inputType ? 'password' : 'text'}
                label='Senha'
                validationMessage={errors.password?.message}
                width='100%'
                marginBottom={10}
                required
                id='password'
                endAdornment={
                  <InputAdornment position='end'>
                    <Tooltip
                      title={iconState ? 'Mostrar senha' : 'Ocultar senha'}
                      placement='top'
                      arrow
                    >
                      <IconButton onClick={togglePassword}>
                        {iconState ? (
                          <VisibilityOff cursor='pointer' color='gray' />
                        ) : (
                          <Visibility cursor='pointer' color='gray' />
                        )}
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                }
              />
            </FormControl>
            {touchedFields && checkString(watch('password'))}
            <TextField
              {...register('passwordConfirmation', {
                // required: 'Obrigatório.',
                validate: (val) => {
                  if (watch('password') != val) {
                    return 'Senhas não coincidem.';
                  }
                },
              })}
              type={inputType ? 'password' : 'text'}
              label='Repetir senha'
              validationMessage={errors.passwordConfirmation?.message}
            />
            <TextField
              {...register('cpfCnpj', {
                required: true,
              })}
              id='cpfCnpj'
              label='CPF/CNPJ'
              placeholder='CPF/CNPJ'
              type='text'
            />
            <TextField
              {...register('mobilePhone', {
                required: true,
              })}
              id='mobilePhone'
              label='Telefone (Whatsapp)'
              placeholder='(99) 99999-9999'
              type='number'
            />
            <FormControl fullWidth>
              <InputLabel id='billingType'>Método de Pagamento</InputLabel>
              <Select
                {...register('billingType', { required: true })}
                labelId='billingType'
                id='billingType-select'
                defaultValue=''
                label='Método de Pagamento'
                // onChange={handleProfileChange}
              >
                <MenuItem value='BOLETO'>Boleto</MenuItem>
                <MenuItem value='CREDIT_CARD'>Cartão</MenuItem>
                <MenuItem value='PIX'>PIX</MenuItem>
                {/* <MenuItem value='ERRO'>SIMULAR ERRO</MenuItem> */}
              </Select>
            </FormControl>
          </div>
          <div className='text-center'>
            <h1 className='font-bold text-xl'>
              Total:{' '}
              <strong className='text-2xl font-bold text-green-800'>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(selectedPrice)}
              </strong>
            </h1>
          </div>
          <Button
            className=''
            variant='contained'
            color='success'
            size='large'
            type='submit'
          >
            Concluir assinatura
          </Button>
          <Backdrop
            sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
            open={open}
            // onClick={handleClose}
          >
            <div className='flex flex-col items-center justify-center gap-4 text-xl text-center'>
              <h1 className='font-bold'>
                Finalizando seu cadastro, aguarde...
              </h1>
              <CircularProgress color='inherit' />
            </div>
          </Backdrop>
        </div>
      </form>
    </div>
  );
};

export default NewCustomerForm;
