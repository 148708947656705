import React, { useEffect, useRef, useState } from 'react';
import '../App.css';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import logo from '../assets/simboloVitaResize.png';
import empresa1 from '../assets/clients/empresa1.png';
import empresa2 from '../assets/clients/empresa2.png';
import empresa3 from '../assets/clients/empresa3.jpg';
import empresa4 from '../assets/clients/empresa4.jpg';
import empresa5 from '../assets/clients/empresa5.jpg';
import empresa6 from '../assets/clients/empresa6.png';
import empresa7 from '../assets/clients/empresa7.jpg';
import empresa8 from '../assets/clients/empresa8.png';
import empresa9 from '../assets/clients/empresa9.jpg';
import empresa10 from '../assets/clients/empresa10.jpg';
import empresa11 from '../assets/clients/empresa11.jpg';
import empresa12 from '../assets/clients/empresa12.png';
import empresa13 from '../assets/clients/empresa13.png';
import Button from '@mui/material/Button';
import HamburgerComponent from './layout/HamburgerComponent';
import {
  CalendarMonth,
  CardGiftcard,
  CheckCircleOutline,
  CloudCircleOutlined,
  ComputerOutlined,
  SmartphoneOutlined,
} from '@mui/icons-material';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Element, Link, scroller } from 'react-scroll';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Drawer, MenuItem, Select } from '@mui/material';
import { useDispatch } from 'react-redux';
import { newCustomerActions } from '../store/newCustomer';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 7,
  },
  largeDesktop: {
    breakpoint: { max: 3000, min: 1300 },
    items: 5,
  },
  mediumDesktop: {
    breakpoint: { max: 1300, min: 1074 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1074, min: 860 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 860, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const responsiveTestimonials = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 2,
  },
  largeDesktop: {
    breakpoint: { max: 3000, min: 1300 },
    items: 2,
  },
  mediumDesktop: {
    breakpoint: { max: 1300, min: 1074 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 1074, min: 860 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 860, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const textArray = ['o crescimento', 'a vitalidade', 'a organização'];

const LandingPage = () => {
  const [selectedText, setSelectedText] = useState('o crescimento');
  const [periodicity1, setPeriodicity1] = useState('MONTHLY');
  const [periodicity2, setPeriodicity2] = useState('MONTHLY');
  const [periodicity3, setPeriodicity3] = useState('MONTHLY');
  const [menuDrawerOpen, setMenuDrawerOpen] = useState(false);

  const planosRef = useRef(null);

  const scrollToPlanos = () => {
    scroller.scrollTo('myScrollToElement', {
      duration: 500,
      smooth: true,
      offset: -80,
    });
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlePurchase = (plan) => () => {
    if (plan === 'LIBERAL') {
      dispatch(newCustomerActions.setSelectedPlan('LIBERAL'));
      dispatch(newCustomerActions.setPeriodicity(periodicity1));
      if (periodicity1 === 'MONTHLY') {
        dispatch(newCustomerActions.setPrice(67));
      }
      if (periodicity1 === 'SEMIANNUALLY') {
        dispatch(newCustomerActions.setPrice(335));
      }
      if (periodicity1 === 'YEARLY') {
        dispatch(newCustomerActions.setPrice(670));
      }
    }
    if (plan === 'AVANÇADO') {
      dispatch(newCustomerActions.setSelectedPlan('AVANÇADO'));
      dispatch(newCustomerActions.setPeriodicity(periodicity2));
      if (periodicity2 === 'MONTHLY') {
        dispatch(newCustomerActions.setPrice(97));
      }
      if (periodicity2 === 'SEMIANNUALLY') {
        dispatch(newCustomerActions.setPrice(435));
      }
      if (periodicity2 === 'YEARLY') {
        dispatch(newCustomerActions.setPrice(970));
      }
    }
    if (plan === 'EMPRESARIAL') {
      dispatch(newCustomerActions.setSelectedPlan('EMPRESARIAL'));
      dispatch(newCustomerActions.setPeriodicity(periodicity3));
      if (periodicity3 === 'MONTHLY') {
        dispatch(newCustomerActions.setPrice(147));
      }
      if (periodicity3 === 'SEMIANNUALLY') {
        dispatch(newCustomerActions.setPrice(735));
      }
      if (periodicity3 === 'YEARLY') {
        dispatch(newCustomerActions.setPrice(1470));
      }
    }
    navigate('/finalizar-cadastro');
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedText((prevText) => {
        const currentIndex = textArray.indexOf(prevText);
        const nextIndex = (currentIndex + 1) % textArray.length;
        return textArray[nextIndex];
      });
    }, 1500);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <div className='w-full'>
      <nav>
        <AppBar position='fixed'>
          <Toolbar className='flex justify-between gap-2 bg-white h-16'>
            <Drawer
              anchor={'right'}
              open={menuDrawerOpen}
              onClose={() => setMenuDrawerOpen(false)}
            >
              <div className='flex flex-col w-64 items-center gap-16 pt-8 h-full'>
                <Link
                  to='benefits'
                  smooth={true}
                  duration={500}
                  offset={-20}
                  className='text-black font-semibold border-white border-b-2 hover:border-blue-500 cursor-pointer duration-500'
                >
                  Benefícios
                </Link>
                <Link
                  to='testimonials'
                  smooth={true}
                  duration={500}
                  offset={-20}
                  className='text-black font-semibold border-white border-b-2 hover:border-blue-500 cursor-pointer duration-500'
                >
                  Depoimentos
                </Link>
                <Link
                  to='howItWorks'
                  smooth={true}
                  duration={500}
                  offset={-20}
                  className='text-black font-semibold border-white border-b-2 hover:border-blue-500 cursor-pointer duration-500'
                >
                  Como funciona
                </Link>
                <Link
                  to='plans'
                  smooth={true}
                  duration={500}
                  offset={30}
                  className='text-black font-semibold border-white border-b-2 hover:border-blue-500 cursor-pointer duration-500'
                >
                  Planos
                </Link>
                <RouterLink
                  to='/privacidade'
                  className='text-black font-semibold border-white border-b-2 hover:border-blue-500 cursor-pointer duration-500'
                >
                  Privacidade
                </RouterLink>
              </div>
            </Drawer>
            <div>
              <img src={logo} alt='vita-logo' className='w-12' />
            </div>
            <div className='flex lg:hidden'>
              <HamburgerComponent setIsShown={() => setMenuDrawerOpen(true)} />
            </div>
            <div className='hidden lg:flex lg:justify-evenly lg:w-full'>
              <Link
                to='benefits'
                smooth={true}
                duration={500}
                offset={-20}
                className='text-black font-semibold border-white border-b-2 hover:border-blue-500 cursor-pointer'
              >
                Benefícios
              </Link>
              <Link
                to='testimonials'
                smooth={true}
                duration={500}
                offset={-20}
                className='text-black font-semibold border-white border-b-2 hover:border-blue-500 cursor-pointer'
              >
                Depoimentos
              </Link>
              <Link
                to='howItWorks'
                smooth={true}
                duration={500}
                offset={-20}
                className='text-black font-semibold hover:border-b-2 hover:border-blue-500 cursor-pointer'
              >
                Como funciona
              </Link>
              <Link
                to='plans'
                smooth={true}
                duration={500}
                offset={30}
                className='text-black font-semibold hover:border-b-2 hover:border-blue-500 cursor-pointer'
              >
                Planos
              </Link>
              <RouterLink
                to='/privacidade'
                className='text-black font-semibold hover:border-b-2 hover:border-blue-500 cursor-pointer'
              >
                Privacidade
              </RouterLink>
            </div>
          </Toolbar>
        </AppBar>
      </nav>
      <div className='flex flex-col items-center justify-center bg-[url("C:\Users\Gustavo\Desktop\Prana\vita_client\src\assets\vita-bg2.png")] bg-cover'>
        <div className='w-3/5 flex flex-col items-center justify-center pt-32 pb-20 gap-12'>
          <h1
            className='text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center font-extrabold'
            style={{ color: '#27E2E7' }}
          >
            A solução definitiva para a sua organização e controle financeiro
          </h1>
          <div className='flex flex-col items-start justify-start w-full'>
            <p className='font-bold text-mg lg:text-lg text-white mb-4'>
              Com o Vita, você:
            </p>
            <p className='font-bold text-mg lg:text-lg text-white'>
              - Faz organização financeira;
            </p>
            <p className='font-bold text-mg lg:text-lg text-white'>
              - tem uma planilha de controle financeiro inteligente;
            </p>
            <p className='font-bold text-mg lg:text-lg text-white'>
              - diminui a sua ansiedade;
            </p>
            <p className='font-bold text-mg lg:text-lg text-white'>
              - aumenta a clareza para tomar decisões;
            </p>
            <p className='font-bold text-mg lg:text-lg text-white'>
              - ganha tempo;
            </p>
            <p className='font-bold text-mg lg:text-lg text-white'>
              - tem mais segurança;
            </p>
          </div>
          <Button variant='contained' onClick={scrollToPlanos}>
            Quero saber mais
          </Button>
        </div>
      </div>
      <div
        className='flex items-center justify-center text-center w-full py-12'
        style={{ backgroundColor: '#27E2E7' }}
      >
        <h1 className='text-lg lg:text-xl font-medium'>
          <span className='font-extrabold text-lg lg:text-xl'>
            Acabe com sua INSEGURANÇA!
          </span>{' '}
          Organização, Crescimento, Sucesso, e Resultado são características que
          o VITA proporciona para você empreendedor!
        </h1>
      </div>
      <div
        className='flex flex-col items-center justify-center text-center w-full py-12 gap-12 px-6'
        style={{ backgroundColor: '#34536A' }}
      >
        <h1
          className='text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-extrabold'
          style={{ color: '#27E2E7' }}
        >
          Conheça o Vita
        </h1>
        <div className='flex justify-evenly flex-wrap flex-shrink gap-6 '>
          <div className='flex flex-col items-center gap-4'>
            <h1 className='text-white text-2xl font-extrabold'>
              Entenda as contas a pagar e a receber
            </h1>
            <div
              className='relative w-full'
              style={{ paddingBottom: '56.25%' }}
            >
              <iframe
                className='absolute top-0 left-0 w-full h-full'
                src='https://www.youtube.com/embed/oAdQZpjbR9M'
                title='VITA - Onboarding 1'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                referrerPolicy='strict-origin-when-cross-origin'
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className='flex flex-col items-center gap-4'>
            <h1 className='text-white text-2xl font-extrabold'>
              Lançamentos simples e rápidos
            </h1>
            <div
              className='relative w-full'
              style={{ paddingBottom: '56.25%' }}
            >
              <iframe
                className='absolute top-0 left-0 w-full h-full'
                src='https://www.youtube.com/embed/jkZD9yySyxo'
                title='VITA - Onboarding 2'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                referrerPolicy='strict-origin-when-cross-origin'
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className='flex flex-col items-center gap-4'>
            <h1 className='text-white text-2xl font-extrabold'>
              Descubra os pontos fortes e fracos do negócio
            </h1>
            <div
              className='relative w-full'
              style={{ paddingBottom: '56.25%' }}
            >
              <iframe
                className='absolute top-0 left-0 w-full h-full'
                src='https://www.youtube.com/embed/yNKgYB8n-Jw'
                title='VITA - Onboarding 3'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                referrerPolicy='strict-origin-when-cross-origin'
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div
        className='flex flex-col gap-12 pt-12 pb-20 bg-gray-100'
        id='benefits'
      >
        <h1
          className='text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center font-extrabold'
          style={{ color: '#27E2E7' }}
        >
          Benefícios do Vita
        </h1>
        <div className='flex flex-col lg:flex-row justify-center items-center lg:items-baseline gap-4 p-6'>
          <div className='flex flex-col w-4/6 lg:w-1/6 items-center gap-3 text-center p-6'>
            <CloudCircleOutlined fontSize='large' sx={{ color: '#27e2e7' }} />
            <h1 className='font-bold text-2xl'>Nuvem</h1>
            <p className='font-medium'>
              Acesse os seus dados de onde você estiver pela internet de forma
              rápida e prática.
            </p>
          </div>
          <div className='flex flex-col w-4/6 lg:w-1/6 items-center gap-3 text-center p-6'>
            <SmartphoneOutlined fontSize='large' sx={{ color: '#27e2e7' }} />
            <h1 className='font-bold text-2xl'>Mobile</h1>
            <p className='font-medium'>
              Faça lançamentos e tenha as informações do seu negócio na palma da
              sua mão.
            </p>
          </div>
          <div className='flex flex-col w-4/6 lg:w-1/6 items-center gap-3 text-center p-6'>
            <ComputerOutlined fontSize='large' sx={{ color: '#27e2e7' }} />
            <h1 className='font-bold text-2xl'>Desktop</h1>
            <p className='font-medium'>
              Faça análises e planejamento com metodologias que empresas de
              sucesso utilizam para gerir e alcançar resultados.
            </p>
          </div>
          <div className='flex flex-col w-4/6 lg:w-1/6 items-center gap-3 text-center p-6'>
            <CalendarMonth fontSize='large' sx={{ color: '#27e2e7' }} />
            <h1 className='font-bold text-2xl'>Customizado</h1>
            <p className='font-medium'>
              Planejamento financeiro específico para o seu modelo de negócio e
              necessidades.
            </p>
          </div>
        </div>
      </div>
      <div
        className='flex flex-col items-center justify-center gap-6 py-6'
        style={{ backgroundColor: '#34536A' }}
        id='testimonials'
      >
        <h1
          className='text-xl md:text-2xl lg:text-3xl xl:text-4xl  font-extrabold text-center'
          style={{ color: '#27E2E7' }}
        >
          Veja o depoimento de alguns dos nossos clientes
        </h1>
        <div className='flex w-full items-center justify-center '>
          <Carousel
            responsive={responsiveTestimonials}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            slidesToSlide={1}
            className='w-5/6'
            swipeable
            draggable
            showDots
            dotListClass='custom-dots'
          >
            {/* <div
              className='relative w-full'
              style={{ paddingBottom: '56.25%' }}
            > */}
            <iframe
              className='object-cover'
              width='450'
              height='230'
              src='https://www.youtube.com/embed/2vgPmd8HnVk'
              title='Depoimento - Geleias Âmbar'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              referrerpolicy='strict-origin-when-cross-origin'
              allowfullscreen
            ></iframe>
            <iframe
              width='450'
              height='230'
              src='https://www.youtube.com/embed/NTy7qNhj_BI'
              title='Depoimento Urutu Barbearia'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              referrerpolicy='strict-origin-when-cross-origin'
              allowfullscreen
            ></iframe>
            <iframe
              width='450'
              height='230'
              src='https://www.youtube.com/embed/ZhJb0lSQFgc'
              title='Depoimento Salgaderia Maria'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              referrerpolicy='strict-origin-when-cross-origin'
              allowfullscreen
            ></iframe>
            <iframe
              width='450'
              height='230'
              src='https://www.youtube.com/embed/eL6X37snmlQ'
              title='Depoimento - Tenda do EVA'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              referrerpolicy='strict-origin-when-cross-origin'
              allowfullscreen
            ></iframe>

            {/* </div> */}
          </Carousel>
        </div>
      </div>
      <div
        className='flex flex-col items-center justify-center gap-6 p-16'
        style={{ backgroundColor: '#53BCC3' }}
      >
        <h1 className='text-7xl text-white'>+400</h1>
        <h1 className='text-2xl font-bold text-white'>
          Empreendedores impactados
        </h1>
        <div className='flex w-full items-center justify-center p-8'>
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            slidesToSlide={1}
            className='w-5/6'
            swipeable
            draggable
            showDots
            dotListClass='custom-dots'
          >
            <img
              src={empresa1}
              alt='empresa1'
              className='object-cover w-44 h-44'
            />
            <img
              src={empresa2}
              alt='empresa2'
              className='object-cover w-44 h-44'
            />
            <img
              src={empresa3}
              alt='empresa3'
              className='object-cover w-44 h-44'
            />
            <img
              src={empresa4}
              alt='empresa4'
              className='object-cover w-44 h-44'
            />
            <img
              src={empresa5}
              alt='empresa5'
              className='object-cover w-44 h-44'
            />
            <img
              src={empresa6}
              alt='empresa6'
              className='object-cover w-44 h-44'
            />
            <img
              src={empresa7}
              alt='empresa7'
              className='object-cover w-44 h-44'
            />
            <img
              src={empresa8}
              alt='empresa8'
              className='object-cover w-44 h-44'
            />
            <img
              src={empresa9}
              alt='empresa9'
              className='object-cover w-44 h-44'
            />
            <img
              src={empresa10}
              alt='empresa10'
              className='object-cover w-44 h-44'
            />
            <img
              src={empresa11}
              alt='empresa11'
              className='object-cover w-44 h-44'
            />
            <img
              src={empresa12}
              alt='empresa12'
              className='object-cover w-44 h-44'
            />
            <img
              src={empresa13}
              alt='empresa13'
              className='object-cover w-44 h-44'
            />
          </Carousel>
        </div>
      </div>
      <div
        className='flex flex-col items-center justify-center text-center gap-4 pt-12 pb-20 bg-gray-100'
        id='howItWorks'
      >
        <h1
          className='text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold'
          style={{ color: '#27E2E7' }}
        >
          Processo de Implantação
        </h1>
        <p className='font-bold text-lg md:text-xl'>
          4 passos simples para <span>{selectedText}</span> da sua empresa
        </p>
        <div className='flex flex-col justify-center items-center lg:items-baseline gap-4 p-6 lg:flex-row'>
          <div className='flex flex-col w-4/6  lg:w-1/6 text-center items-center gap-4 p-6 '>
            <div
              className='flex w-24 h-24 rounded-full items-center justify-center'
              style={{ backgroundColor: '#34526A' }}
            >
              <p
                className='text-2xl font-extrabold'
                style={{ color: '#27E2E7' }}
              >
                1
              </p>
            </div>
            <h1 className='text-xl font-bold' style={{ color: '#27E2E7' }}>
              Modelagem financeira
            </h1>
            <p>
              Processo realizado junto com um de nossos consultores de forma
              totalmente personalizada para o modelo da sua empresa.
            </p>
          </div>
          <div className='flex flex-col w-4/6  lg:w-1/6 text-center items-center gap-4 p-6 '>
            <div
              className='flex w-24 h-24 rounded-full items-center justify-center'
              style={{ backgroundColor: '#34526A' }}
            >
              <p
                className='text-2xl font-extrabold'
                style={{ color: '#27E2E7' }}
              >
                2
              </p>
            </div>
            <h1 className='text-xl font-bold' style={{ color: '#27E2E7' }}>
              Registro e apresentação da plataforma
            </h1>
            <p>
              Criação da conta no VITA e análise dos grupos de contas.
              Apresentação da interface e das ferramentas e suas
              funcionalidades.
            </p>
          </div>
          <div className='flex flex-col w-4/6  lg:w-1/6 text-center items-center gap-4 p-6 '>
            <div
              className='flex w-24 h-24 rounded-full items-center justify-center'
              style={{ backgroundColor: '#34526A' }}
            >
              <p
                className='text-2xl font-extrabold'
                style={{ color: '#27E2E7' }}
              >
                3
              </p>
            </div>
            <h1 className='text-xl font-bold' style={{ color: '#27E2E7' }}>
              Onboarding da plataforma
            </h1>
            <p>
              Apresentação das metodologias de controle financeiro que o VITA te
              fornece, através dos lançamentos, de maneira automática.
            </p>
          </div>
          <div className='flex flex-col w-4/6  lg:w-1/6 text-center items-center gap-4 p-6 '>
            <div
              className='flex w-24 h-24 rounded-full items-center justify-center'
              style={{ backgroundColor: '#34526A' }}
            >
              <p
                className='text-2xl font-extrabold'
                style={{ color: '#27E2E7' }}
              >
                4
              </p>
            </div>
            <h1 className='text-xl font-bold' style={{ color: '#27E2E7' }}>
              Leitura das informações
            </h1>
            <p>
              Aprendizado com os nossos consultores para a leitura e
              entendimento dos números reais do seu negócio, com geração de um
              plano de ação e tomadas de decisão que garantam o sucesso da sua
              organização.
            </p>
          </div>
        </div>
        <Button variant='contained' onClick={scrollToPlanos}>
          Quero saber mais
        </Button>
      </div>
      <div
        className='flex flex-col items-center justify-center gap-12 pt-12 pb-20 bg-gray-100'
        id='plans'
        ref={planosRef}
      >
        <Element name='myScrollToElement'>
          <h1 className='text-5xl font-bold' style={{ color: '#27E2E7' }}>
            Planos
          </h1>
        </Element>
        <div className='flex flex-col lg:flex-row gap-8'>
          <div className='flex flex-col gap-2 border-black border-2 bg-white shadow-md rounded-md h-fit'>
            <div
              className='flex justify-center'
              style={{ backgroundColor: '#27E2E7' }}
            >
              <h1 className='font-extrabold text-2xl p-3'>Liberal</h1>
            </div>
            <div className='flex justify-center'>
              <h1 className='text-2xl font-bold pt-1'>R$</h1>
              <h1 className='text-7xl font-extrabold'>
                {periodicity1 === 'MONTHLY'
                  ? '67'
                  : periodicity1 === 'SEMIANNUALLY'
                  ? '335'
                  : '670'}
              </h1>
              <h1 className='text-2xl font-bold pt-1'>,00</h1>
            </div>
            <div className='flex justify-center'>
              <h1 className='text-xl font-bold pt-1 text-green-600'>
                {periodicity1 === 'SEMIANNUALLY'
                  ? 'Economize 1 mensalidade'
                  : periodicity1 === 'YEARLY'
                  ? 'Economize 2 mensalidades'
                  : ''}
              </h1>
            </div>
            <div className='flex justify-center'>
              <Select
                value={periodicity1}
                variant='outlined'
                onChange={(e) => setPeriodicity1(e.target.value)}
              >
                <MenuItem value='MONTHLY'>Mensal</MenuItem>
                <MenuItem value='SEMIANNUALLY'>Semestral</MenuItem>
                <MenuItem value='YEARLY'>Anual</MenuItem>
              </Select>
            </div>
            <div className='flex justify-center'>
              <h1 className='text-sm font-bold pt-1 '>
                {`${
                  periodicity1 === 'SEMIANNUALLY'
                    ? '1 cobrança a cada 6 meses'
                    : periodicity1 === 'YEARLY'
                    ? '1 cobrança a cada 12 meses'
                    : '1 cobrança por mês'
                }`}
              </h1>
            </div>
            <div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Modelagem financeira personalizada</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Plano de contas a pagar e receber</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Fluxo de caixa</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Lançamentos</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>DRE e DFC preenchidas automaticamente</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CardGiftcard />
                <p>Atendimento vitalício</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CardGiftcard />
                <p>Implantação com um de nossos consultores</p>
              </div>
            </div>
            <div className='p-8 flex flex-col gap-8 justify-center items-center'>
              <Button
                variant='contained'
                color='success'
                onClick={handlePurchase('LIBERAL')}
              >
                Contratar
              </Button>
              <p className='text-sm font-bold'>
                Para faturamentos até R$6.000,00
              </p>
            </div>
          </div>
          <div className='flex flex-col gap-2 border-black border-2 bg-white shadow-md rounded-md h-fit'>
            <div
              className='flex justify-center'
              style={{ backgroundColor: '#27E2E7' }}
            >
              <h1 className='font-extrabold text-2xl p-3'>Avançado</h1>
            </div>
            <div className='flex justify-center'>
              <h1 className='text-2xl font-bold pt-1'>R$</h1>
              <h1 className='text-7xl font-extrabold'>
                {periodicity2 === 'MONTHLY'
                  ? '97'
                  : periodicity2 === 'SEMIANNUALLY'
                  ? '435'
                  : '970'}
              </h1>
              <h1 className='text-2xl font-bold pt-1'>,00</h1>
            </div>
            <div className='flex justify-center'>
              <h1 className='text-xl font-bold pt-1 text-green-600'>
                {periodicity2 === 'SEMIANNUALLY'
                  ? 'Economize 1 mensalidade'
                  : periodicity2 === 'YEARLY'
                  ? 'Economize 2 mensalidades'
                  : ''}
              </h1>
            </div>
            <div className='flex justify-center'>
              <Select
                value={periodicity2}
                variant='outlined'
                onChange={(e) => setPeriodicity2(e.target.value)}
              >
                <MenuItem value='MONTHLY'>Mensal</MenuItem>
                <MenuItem value='SEMIANNUALLY'>Semestral</MenuItem>
                <MenuItem value='YEARLY'>Anual</MenuItem>
              </Select>
            </div>
            <div className='flex justify-center'>
              <h1 className='text-sm font-bold pt-1 '>
                {`${
                  periodicity2 === 'SEMIANNUALLY'
                    ? '1 cobrança a cada 6 meses'
                    : periodicity2 === 'YEARLY'
                    ? '1 cobrança a cada 12 meses'
                    : '1 cobrança por mês'
                }`}
              </h1>
            </div>
            <div className='flex justify-center'>
              <h1 className='text-xl font-bold pt-1 text-red-600'>
                {periodicity2 === 'YEARLY' && 'MAIS VENDIDO'}
              </h1>
            </div>
            <div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Modelagem financeira personalizada</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Plano de contas a pagar e receber</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Fluxo de caixa</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Lançamentos</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>DRE e DFC preenchidas automaticamente</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Gráficos</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Análise com dashboard interativo</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CardGiftcard />
                <p>Atendimento vitalício</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CardGiftcard />
                <p>Implantação com um de nossos consultores</p>
              </div>
              <div className='p-8 flex flex-col gap-8 justify-center items-center'>
                <Button
                  variant='contained'
                  color='success'
                  onClick={handlePurchase('AVANÇADO')}
                >
                  Contratar
                </Button>
                <p className='text-sm font-bold'>
                  Para faturamentos entre R$6.000,00 e R$15.000,00
                </p>
              </div>
            </div>
          </div>
          <div className='flex flex-col gap-2 border-black border-2 bg-white shadow-md rounded-md h-fit'>
            <div
              className='flex justify-center'
              style={{ backgroundColor: '#34526A' }}
            >
              <h1 className='font-extrabold text-2xl p-3 text-white'>
                Empresarial
              </h1>
            </div>
            <div className='flex justify-center'>
              <h1 className='text-2xl font-bold pt-1'>R$</h1>
              <h1 className='text-7xl font-extrabold'>
                {periodicity3 === 'MONTHLY'
                  ? '147'
                  : periodicity3 === 'SEMIANNUALLY'
                  ? '735'
                  : '1.470'}
              </h1>
              <h1 className='text-2xl font-bold pt-1'>,00</h1>
            </div>
            <div className='flex justify-center'>
              <h1 className='text-xl font-bold pt-1 text-green-600'>
                {periodicity3 === 'SEMIANNUALLY'
                  ? 'Economize 1 mensalidade'
                  : periodicity3 === 'YEARLY'
                  ? 'Economize 2 mensalidades'
                  : ''}
              </h1>
            </div>
            <div className='flex justify-center'>
              <Select
                value={periodicity3}
                variant='outlined'
                onChange={(e) => setPeriodicity3(e.target.value)}
              >
                <MenuItem value='MONTHLY'>Mensal</MenuItem>
                <MenuItem value='SEMIANNUALLY'>Semestral</MenuItem>
                <MenuItem value='YEARLY'>Anual</MenuItem>
              </Select>
            </div>
            <div className='flex justify-center'>
              <h1 className='text-sm font-bold pt-1 '>
                {`${
                  periodicity3 === 'SEMIANNUALLY'
                    ? '1 cobrança a cada 6 meses'
                    : periodicity3 === 'YEARLY'
                    ? '1 cobrança a cada 12 meses'
                    : '1 cobrança por mês'
                }`}
              </h1>
            </div>
            <div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Modelagem financeira personalizada</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Plano de contas a pagar e receber</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Fluxo de caixa</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Lançamentos</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>DRE e DFC preenchidas automaticamente</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Gráficos e calendário preenchidos automaticamente</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Análise com dashboard interativo</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CheckCircleOutline />
                <p>Ferramenta de conciliação bancária</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CardGiftcard />
                <p>Atendimento vitalício</p>
              </div>
              <div className='p-4 flex gap-4 border-b-2 border-gray-500'>
                <CardGiftcard />
                <p>Implantação com um de nossos consultores</p>
              </div>
              <div className='p-8 flex flex-col gap-8 justify-center items-center'>
                <Button
                  variant='contained'
                  color='success'
                  onClick={handlePurchase('EMPRESARIAL')}
                >
                  Contratar
                </Button>
                <p className='text-sm font-bold'>
                  Para faturamentos superiores a R$15.000,00
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
