let development;
let testServer;
let production;

export const serverMode = 'production';

export const backendUrl =
  serverMode === 'development'
    ? 'http://localhost:3333'
    : serverMode === 'testServer'
    ? 'https://testadminapi.vita.app.br'
    : 'https://adminapi.vita.app.br';
