import React, { useState } from 'react';

import { auth } from '../../firebase-config';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';

import {
  Button,
  Card,
  EyeOffIcon,
  EyeOpenIcon,
  Heading,
  Link,
  Text,
  TextInputField,
  Tooltip,
  Position,
  Checkbox,
} from 'evergreen-ui';

import { toastNotification } from '../../shared/toastNotification';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { signIn } from '../../services/api/signInUpService';
// import { setAuth } from '../../redux/auths/auth.actions';

import { loginValuesActions } from '../../store/loginValues';

import { useForm } from 'react-hook-form';

import { checkString } from '../../helpers/signInUpValidation';
import { getVitaUserByIdentityId } from '../../services/api/vitaUserService';
import { toast } from 'react-toastify';
import { backendUrl } from '../../config/config';

export default function SignInComponent() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const companyId = localStorage.getItem('companyId');
  const userId = localStorage.getItem('userId');

  const {
    formState,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const {
    errors,
    dirtyFields,
    isDirty,
    isSubmitting,
    touchedFields,
    submitCount,
  } = formState;

  const fetchLogin = (signInObject) => {
    setLoading(true);
    let statusResponse = false;
    let toastContent = '';
    signInObject = {
      email: signInObject.email.toLowerCase().trim(),
      password: signInObject.password,
    };

    signInWithEmailAndPassword(auth, signInObject.email, signInObject.password)
      .then((userCredential) => {
        console.log('User logged in: ', userCredential);
        getVitaUserByIdentityId(userCredential.user.uid)
          .then((userResponse) => {
            console.log('User response: ', userResponse);
            if (!userResponse.data.customerId) {
              navigate('/empresas');
              return;
            }
            fetch(`${backendUrl}/subscriptions/check`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                user: userResponse.data,
              }),
            })
              .then((response) => {
                if (response.status === 200) {
                  return response.json();
                } else {
                  return response.text().then((text) => {
                    throw new Error(text);
                  });
                }
              })
              .then((res) => {
                console.log('Subscription data: ', res);
                console.log(userResponse.data);
                statusResponse = 'success';
                toastContent = 'Logado com sucesso';
                setLoading(false);
                toastNotification(statusResponse, toastContent);
                navigate('/empresas');
              })
              .catch((error) => {
                signOut(auth);
                console.log('Subscription error: ', error.message);
                toast.error(error.message);
                return;
              });
          })
          .catch((error) => {
            console.log('catch error: ', error);
            if (error == undefined)
              toastContent =
                'Parece que falhamos aqui. Por favor, tente novamente.';
            else toastContent = error.data.errors.Mensagens[0];

            statusResponse = 'error';
          });
      })
      .catch((error) => {
        if (error.code === 'auth/user-not-found') {
          statusResponse = 'error';
          toastContent = 'Usuário não encontrado.';
          console.log('User not found.');
        } else if (error.code === 'auth/invalid-credential') {
          statusResponse = 'error';
          toastContent = 'Senha incorreta.';
          console.log('Password incorrect.');
        } else if (error.code === 'auth/too-many-requests') {
          statusResponse = 'error';
          toastContent = 'Muitas tentativas. Tente novamente mais tarde.';
          console.log('Too many requests.');
        } else {
          statusResponse = 'error';
          toastContent =
            'Parece que falhamos aqui. Por favor, tente novamente.';
          console.log(error.code);
        }
        toastNotification(statusResponse, toastContent);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [inputType, setInputType] = useState(true);
  const [iconState, setIconState] = useState(true);

  const togglePassword = () => {
    setInputType((old) => !old);
    setIconState((old) => !old);
  };

  return (
    <Card
      elevation={4}
      float='left'
      background='white'
      margin={24}
      padding={16}
      display='flex'
      height='fit-content'
      flexDirection='column'
    >
      <Heading size={700} marginY={15}>
        Login
      </Heading>
      <form
        id='signup-form'
        onSubmit={handleSubmit((data) => {
          fetchLogin(data);
        })}
      >
        <TextInputField
          {...register(
            'email',

            {
              pattern: {
                value:
                  /^[\sa-zA-Z0-9._-]+@[a-zA-Z0-9]{2,}.[a-zA-Z]{2,}(.?[a-z\s]{2,})?$/gm,
                message: 'Digite um e-mail válido.',
              },
            }
          )}
          minWidth={250}
          label='E-mail'
          type='text'
          validationMessage={errors?.email?.message}
          required
        />
        <div style={{ display: 'flex' }}>
          <TextInputField
            {...register(
              'password',

              {
                pattern: {
                  value:
                    /^(?=.*\d)(?=.*[!@#$%&*])(?=.*[a-z])(?=.*[A-Z]).{6,20}$/gm,
                  // message: 'Padrão incorreto.',
                },
                minLength: {
                  value: 6,
                  // message: 'A senha deve conter pelo menos 6 caracteres',
                },
              }
            )}
            type={inputType ? 'password' : 'text'}
            label='Senha'
            validationMessage={errors.password?.message}
            width='100%'
            marginBottom={10}
            required
          />

          {iconState ? (
            <Tooltip
              content={<Text fontSize={12}>Mostrar senha</Text>}
              position={Position.TOP}
              showDelay={300}
            >
              <EyeOffIcon
                color='gray'
                onClick={togglePassword}
                alignSelf='center'
                marginLeft={-25}
                marginTop={18}
                cursor='pointer'
                opacity='50%'
                size={18}
              />
            </Tooltip>
          ) : (
            <Tooltip
              content={<Text fontSize={12}>Ocultar senha</Text>}
              position={Position.TOP}
              showDelay={300}
            >
              <EyeOpenIcon
                color='gray'
                onClick={togglePassword}
                alignSelf='center'
                marginLeft={-25}
                marginTop={18}
                cursor='pointer'
              />
            </Tooltip>
          )}
        </div>
        {touchedFields && checkString(watch('password'))}
        <Checkbox
          label='Manter conectado'
          checked={checked}
          onChange={(e) => setChecked(e.target.checked)}
        />
        <Link href='/alterar-senha' size={300}>
          Esqueci minha senha
        </Link>
      </form>
      <Button
        size='large'
        marginTop={12}
        isLoading={loading}
        // onClick={() => fetchLogin()}
        appearance='minimal'
        color='blue'
        form='signup-form'
        type='submit'
      >
        Entrar
      </Button>

      <Card
        display='flex'
        flexDirection='column'
        gap={0}
        justifyContent='space-between'
      ></Card>
      <style jsx='true'>{``}</style>
    </Card>
  );
}
