import { Heading, SideSheet } from 'evergreen-ui';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryUserRolesCurrentRole } from '../../services/api/userRolesService';
import LoadingOverlay from '../../shared/LoadingOverlay';

function DrawerComponent({ isShown, setIsShown }) {
  const companyId = localStorage.getItem('companyId');
  const userId = localStorage.getItem('userId');

  const navigate = useNavigate();
  const navigateTo = (route) => {
    setIsShown(false);
    navigate(route);
  };

  const { data: currentRole, isLoading: isLoadingCurrentRole } =
    useQueryUserRolesCurrentRole(
      { companyId: companyId, vitaUserId: userId },
      {
        revalidateIfStale: true,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }
    );

  if (isLoadingCurrentRole) {
    return <LoadingOverlay />;
  }

  return (
    <SideSheet
      className='DrawerComponent'
      isShown={isShown}
      onCloseComplete={() => setIsShown(false)}
      preventBodyScrolling
      position='left'
      width={250}
    >
      <Heading
        className='heading'
        size={600}
        margin={40}
        color='#333'
        onClick={() => navigateTo('lancamentos')}
      >
        Lançamentos
      </Heading>
      {!isLoadingCurrentRole && currentRole?.data?.userRole !== 'LANÇADOR' && (
        <Heading
          className='heading'
          size={600}
          margin={40}
          color='#333'
          onClick={() => navigateTo('dashboard')}
        >
          Dashboard
        </Heading>
      )}

      {!isLoadingCurrentRole && currentRole?.data?.userRole !== 'LANÇADOR' && (
        <Heading
          className='heading'
          size={600}
          margin={40}
          color='#333'
          onClick={() => navigateTo('declaracao')}
        >
          Declaração
        </Heading>
      )}

      {!isLoadingCurrentRole && currentRole?.data?.userRole !== 'ANALISTA' && (
        <Heading
          className='heading'
          size={600}
          margin={40}
          color='#333'
          onClick={() => navigateTo('categorias')}
        >
          Categorias
        </Heading>
      )}
      {!isLoadingCurrentRole && currentRole?.data?.userRole !== 'ANALISTA' && (
        <Heading
          className='heading'
          size={600}
          margin={40}
          color='#333'
          onClick={() => navigateTo('contas')}
        >
          Bancos
        </Heading>
      )}
      <Heading
        className='heading'
        size={600}
        margin={40}
        color='#333'
        onClick={() => navigateTo('calendario')}
      >
        Calendário
      </Heading>
      <Heading
        className='heading'
        size={600}
        margin={40}
        color='#333'
        onClick={() => navigateTo('conciliacao')}
      >
        Conciliação
      </Heading>
      <style jsx='true'>
        {`
          .iconOpener {
            padding: 8px;
            transform: rotate(90deg);
            font-weight: bold;
          }
          .iconOpener:hover {
            cursor: pointer;
          }
          .heading {
            cursor: pointer;
          }
          .heading:hover {
            color: #1e2bdd;
          }
        `}
      </style>
    </SideSheet>
  );
}

export default DrawerComponent;
