import React, { useEffect, useState } from 'react';
import {
  Dialog,
  Heading,
  TextInputField,
  FormField,
  Button,
  Pane,
  IconButton,
  SelectField,
  CrossIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  Switch,
} from 'evergreen-ui';
import { Container, Row, Col, setConfiguration } from 'react-grid-system';

import { mutate } from 'swr';
import { useForm } from 'react-hook-form';

import { useQueryCategories } from '../../services/api/categoriesService';
import { useQueryBanks } from '../../services/api/banksService';
import { postEntry } from '../../services/api/entriesService';
import {
  getIntentColorByJournal,
  getNameByJournal,
} from '../../helpers/entriesHelper';
import styles from './EntryFormComponent.module.css';

import DividerComponent from '../../custom/DividerComponent';

import { toastNotification } from '../../shared/toastNotification';
import { formatWithLimitedDecimals } from '../../helpers/moneyFormatter';
import { useQueryVitaUserByIdentityId } from '../../services/api/vitaUserService';
import { convertDate, convertDate2 } from '../../helpers/dateConverter';
import { useSelector } from 'react-redux';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

function EntryCopyFormComponent({ isShown, setIsShown, journalEntry = {} }) {
  const companyId = localStorage.getItem('companyId');
  const userId = localStorage.getItem('userId');

  /* Dialog settings  */

  const [isOpenAccordion, setIsOpenAccordion] = useState(true);

  /* API calls */

  const {
    data: vitaUser,
    isLoading: isLoadingVitaUser,
    isError,
  } = useQueryVitaUserByIdentityId(userId, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const { data: categories = [], isLoading: isLoadingCategories } =
    useQueryCategories(
      // company.data.id,
      companyId,
      {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }
    );

  const { data: banks = [], isLoading: isLoadingBanks } = useQueryBanks(
    // company.data.id,
    companyId,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  /* Form */

  const {
    formState,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: journalEntry,
  });

  useEffect(() => {
    if (isShown) {
      const entry = {
        ...journalEntry,
        dateCompetencia: journalEntry.dateCompetencia.substr(0, 10),
        dateVencimento: journalEntry.dateVencimento.substr(0, 10),
      };
      reset(entry);
      setValue(
        'value',
        journalEntry.value.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
        })
      );
      setValue(
        'fineValue',
        journalEntry.fineValue.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
        })
      );
      setValue(
        'discountValue',
        journalEntry.discountValue.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
        })
      );
      setValue(
        'interestValue',
        journalEntry.interestValue.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
        })
      );
      setValue(
        'interestValuePercent',
        formatWithLimitedDecimals(
          (Number(
            getValues('interestValue').replace(/\./g, '').replace(',', '.')
          ) /
            Number(getValues('value').replace(/\./g, '').replace(',', '.'))) *
            100
        )
      );
      setIsOpenAccordion(true);
    }
  }, [isShown]);

  setConfiguration({
    containerWidths: '100%',
    gutterWidth: '12',
  });

  const handleInput = (event, type) => {
    let inputVal = event.target.value.replace(/\D/g, '');
    if (inputVal === '') {
      setValue(type, '');
      return;
    }
    inputVal = inputVal.padStart(3, '0');
    const wholeNum = inputVal.slice(0, -2);
    const decimal = inputVal.slice(-2);
    const formattedWholeNum = Number(wholeNum).toLocaleString();
    const formattedValue = `${formattedWholeNum},${decimal}`;

    // Convert the formatted value to a number with two decimal places
    const numericValue = Number(
      formattedValue.replace(/\./g, '').replace(',', '.')
    );
    setValue(type, formattedValue);
    console.log(numericValue); // Output the numeric value to the console
    console.log(watch(type)); // Output the numeric value to the console
  };

  const handleInstallments = (multiplier) => {
    const tempDate = new Date(getValues('dateVencimento'));

    tempDate.setMonth(tempDate.getMonth() + multiplier);

    // console.log(convertDate2(tempDate.toISOString()));
    if (getValues('dateCompetencia'))
      return convertDate2(tempDate.toISOString());
  };

  const buildFormHeader = () => {
    return (
      <Pane
        display='flex'
        flex={1}
        justifyContent='space-between'
        alignItems='center'
      >
        <Button
          appearance='primary'
          intent={getIntentColorByJournal(journalEntry)}
          height={48}
          marginRight={16}
        >
          {getNameByJournal(journalEntry)}
        </Button>
        <Button
          appearance='minimal'
          intent='selected'
          height={28}
          marginRight={26}
          style={{
            background: '#FEEAB4',
            color: 'black',
          }}
          cursor='normal'
        >
          Modo Clonar
        </Button>
        <IconButton appearance='minimal' icon={CrossIcon} onClick={onClose} />
      </Pane>
    );
  };

  const buildFormFooter = () => {
    return (
      <Pane>
        <Button form='entry-form' type='submit' width='fit-content'>
          Salvar
        </Button>
      </Pane>
    );
  };

  const onSubmit = (entry) => {
    console.log('onSubmit');
    let statusResponse = false;
    let toastContent = '';

    let entryToCreate = {
      companyId: companyId,
      journalEntryType: 1,
      isInput: journalEntry.isInput,
      userUpdateId: !isLoadingVitaUser && vitaUser?.data?.id,
      userCreateId: !isLoadingVitaUser && vitaUser?.data?.id,
      dateCompetencia: entry.dateCompetencia,
      dateVencimento: entry.dateVencimento,
      description: entry.description,
      statusPayment: entry.statusPayment,
      paymentType: 1,
      discountValue: entry.discountValue
        ? Number(entry.discountValue.replace(/\./g, '').replace(',', '.'))
        : 0,
      fineValue: !!entry.fineValue
        ? Number(entry.fineValue.replace(/\./g, '').replace(',', '.'))
        : 0,
      interestValue: !!entry.interestValue
        ? Number(entry.interestValue.replace(/\./g, '').replace(',', '.'))
        : 0,
      value:
        typeof entry.value === typeof 5
          ? entry.value
          : Number(entry.value.replace(/\./g, '').replace(',', '.')),
      categoryId: entry.categoryId,
      bankAccountId: entry.bankAccountId,
      bankAccountIdDestination: entry.bankAccountIdDestination,
      repetitionsCount: entry.repetitionsCount,
      repetitionsType: 'string',
      lockDateCompetencia: entry.lockDateCompetencia,
    };

    postEntry(entryToCreate)
      .then((res) => {
        console.log('res post entry: ', res);
        mutate('entriesFilteredByDate');
        mutate('bankBalances');
        statusResponse = 'success';
        toastContent = 'Lançamento salvo com sucesso.';
        onClose();
      })
      .catch((err) => {
        statusResponse = 'warning';
        console.log('post err', err);
        toastContent = `Verifique os campos novamente.`;
      })
      .finally(() => {
        toastNotification(statusResponse, toastContent);
        mutate('banks');
      });
  };

  const onClose = () => {
    console.log('onClose');
    reset();
    setIsOpenAccordion(false);
    setIsShown(false);
  };

  const openAdvancedOptions = () => {
    setIsOpenAccordion((prevState) => !prevState);
  };

  return (
    <Dialog
      isShown={isShown}
      title='Modo Clonar'
      onCloseComplete={onClose}
      confirmLabel='Custom Label'
      hasFooter={true}
      header={buildFormHeader}
      footer={buildFormFooter}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={true}
      width={800}
      backgroundColor='#f8f8f8'
    >
      <div className={styles.dialogWrapper}>
        <form id='entry-form' onSubmit={handleSubmit(onSubmit)}>
          <FormField className={styles.formArea}>
            <Container
              fluid={true}
              style={{
                width: '100%',
                margin: 'none',
                padding: 'none',
              }}
            >
              <Row>
                <Col sm={12} md={6} lg={4}>
                  <TextInputField
                    {...register('dateCompetencia')}
                    label='Data de competência'
                    id='dateCompetencia'
                    name='dateCompetencia'
                    type='date'
                  />
                </Col>
                <Col sm={12} md={6} lg={4}>
                  <TextInputField
                    {...register('dateVencimento')}
                    label='Data de vencimento'
                    id='dateVencimento'
                    name='dateVencimento'
                    type='date'
                  />
                </Col>

                <Col sm={12} md={12} lg={4}>
                  <SelectField
                    {...register('bankAccountId')}
                    label='Conta'
                    onChange={(e) => setValue('bankAccountId', e.target.value)}
                  >
                    {!isLoadingBanks &&
                      banks.data
                        .sort((a, b) => {
                          const nameA = a.name;
                          const nameB = b.name;

                          if (nameA < nameB) {
                            return -1;
                          }

                          if (nameA > nameB) {
                            return 1;
                          }

                          return 0;
                        })
                        .map((bank) => {
                          return (
                            <option key={bank.id} value={bank.id}>
                              {bank.name}
                            </option>
                          );
                        })}
                  </SelectField>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6} lg={6}>
                  <SelectField
                    {...register('categoryId')}
                    label='Categoria'
                    onChange={(e) => setValue('categoryId', e.target.value)}
                  >
                    {!isLoadingCategories &&
                      categories.data
                        .filter((category) => {
                          return category.isInput === watch('isInput');
                        })
                        .sort((a, b) => {
                          const nameA = a.name;
                          const nameB = b.name;

                          if (nameA < nameB) {
                            return -1;
                          }

                          if (nameA > nameB) {
                            return 1;
                          }

                          return 0;
                        })
                        .map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                  </SelectField>
                </Col>
                <Col sm={12} md={6} lg={6}>
                  <TextInputField
                    {...register('description')}
                    label='Descrição'
                    id='description'
                    name='description'
                    placeholder=''
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={10} sm={11} md={11} lg={11}>
                  <TextInputField
                    {...register('value', {
                      required: true,
                      // min: 0,
                      // valueAsNumber: true,
                    })}
                    defaultValue='0,00'
                    label='Valor (R$)'
                    type='text'
                    // min='0,00'
                    id='value'
                    name='value'
                    placeholder=''
                    // step='0.01'
                    onChange={handleInput}
                  />
                </Col>
                <Col xs={2} sm={1} md={1} lg={1}>
                  <Heading size={400} marginBottom={16}>
                    Pago
                  </Heading>
                  <Switch
                    {...register('statusPayment')}
                    checked={watch('statusPayment')}
                    onChange={(e) => {
                      setValue('statusPayment', e.target.checked);
                    }}
                  />
                </Col>
              </Row>
              <Pane display='flex' flexWrap='nowrap'>
                <DividerComponent padding='0px' color='#e8e8e8' />
                {isOpenAccordion ? (
                  <IconButton
                    icon={ChevronUpIcon}
                    type='button'
                    onClick={openAdvancedOptions}
                  />
                ) : (
                  <IconButton
                    icon={ChevronDownIcon}
                    type='button'
                    onClick={openAdvancedOptions}
                  />
                )}
              </Pane>
              <div
                style={{
                  display: `${isOpenAccordion ? 'initial' : 'none'}`,
                }}
              >
                <Row>
                  <Col xs={9} sm={9} md={8}>
                    <TextInputField
                      {...register('interestValue')}
                      label='Juros'
                      type='text'
                      defaultValue='0,00'
                      id='interestValue'
                      name='interestValue'
                      placeholder=''
                      disabled={!watch('value')}
                      onChange={(val) => {
                        console.log(typeof getValues('value'));
                        let v =
                          (val.target.value /
                            Number(
                              getValues('value')
                                .replace(/\./g, '')
                                .replace(',', '.')
                            )) *
                          100;
                        setValue(
                          'interestValuePercent',
                          formatWithLimitedDecimals(v)
                        );
                        handleInput(val, 'interestValue');
                      }}
                    />
                  </Col>
                  <Col xs={3} sm={3} md={4}>
                    <TextInputField
                      {...register('interestValuePercent')}
                      label='%'
                      type='number'
                      min='0'
                      id='interestValuePercent'
                      name='interestValuePercent'
                      defaultValue={0}
                      placeholder=''
                      step='0.01'
                      disabled={!watch('value')}
                      onChange={(val) => {
                        let v =
                          (val.target.value *
                            Number(
                              getValues('value')
                                .replace(/\./g, '')
                                .replace(',', '.')
                            )) /
                          100;
                        setValue('interestValue', formatWithLimitedDecimals(v));
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={12} lg={6}>
                    <SelectField
                      label='Lançar mensalmente até'
                      defaultValue='0'
                      {...register('repetitionsCount')}
                    >
                      <option value='0'></option>
                      {new Array(59).fill(0).map((_, index) => {
                        return (
                          <option key={index} value={index + 1}>
                            {`Vencimento: ${handleInstallments(index + 1)} - ${
                              index + 2
                            }x`}
                          </option>
                        );
                      })}
                    </SelectField>
                  </Col>
                  <Col sm={12} md={12} lg={6} style={{ margin: 'auto' }}>
                    <div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...register('lockDateCompetencia')}
                              onChange={(e) => {
                                setValue(
                                  'lockDateCompetencia',
                                  e.target.checked
                                );
                              }}
                            />
                          }
                          label='Manter data de competência?'
                        />
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </FormField>
        </form>
      </div>

      {/* <div> Rodapé </div> */}
    </Dialog>
  );
}

export default EntryCopyFormComponent;
